import Card, { CardProps } from '~/components/layout/Card';
import LegacyResponsiveImage from '~/components/LegacyResponsiveImage';
import ResponsiveImage from '~/components/ResponsiveImage';

export default function ImageCard({ image, children, ...props }: Omit<CardProps, 'flush'> & { image: string }) {
  return (
    <Card flush {...props}>
      <div className="@container flex max-sm:flex-col items-stretch w-full group">
        <div className="sm:flex-shrink-0 sm:w-[200px] @lg:sm:w-[250px] @2xl:sm:w-[350px] max-sm:h-24 sm:min-h-52 relative sm:opacity-70 group-hover:opacity-100 transition max-sm:border-b-2 sm:border-r-2 border-theme-separator">
          <ResponsiveImage
            path={image}
            className="absolute inset-0 w-full h-full object-cover max-sm:rounded-t-theme sm:rounded-l-theme"
            width={350}
          />
        </div>
        <div className="py-5 px-6 flex items-center">{children}</div>
      </div>
    </Card>
  );
}
